import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { tokens } from './configs/CultTokensConfig.js'

//import door from './imgs/door.gif'
//import famgif from './imgs/fam.gif'

import door from './imgs/doorslow.gif'
//import famgif from './imgs/famslow.gif'

//import door from './imgs/doorslowbig.gif'
import famgif from './imgs/famsmooth.gif'

import dustgif from './imgs/dust.gif'
const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}

function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");

 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (!connected) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();


  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));

  var fam = new web3.eth.Contract(
    tokens["fam"]["abi"], 
    tokens["fam"]["address"]) 
  
  const [famBal, setFamBal] = React.useState(0)

  const [famList, setFamList] = React.useState([])

  const letItRip = () => {
    console.log("let it rip")
    
    fam.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setFamBal(res)
        console.log(res)
      })

    fam.methods.getOwned(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setFamList(res)
        console.log(res)
      })






  }

  const [famVisible, setFamVisible] = React.useState([])

  const [famImg, setFamImg] = React.useState("")
  const [famAttr, setFamAttr] = React.useState([])
  const fetchFam = (index) => {

    let x = "https://fam.farmgod.finance/json/" + famList[index] + ".json"
    console.log(x)
    fetch(x,).then((res)=>{
        return res.json()
      })
      .then((theResult)=>{
        setFamImg("https://ipfs.io/ipfs/" + theResult.image.replace("ipfs://",""))
        setFamAttr(theResult.attributes)
      })
  }

  const [activeFam, setActiveFam] = React.useState(0)

  const nextFam = () => {
    if (activeFam + 1 < famList.length) {
      let ix = activeFam + 1
      setActiveFam(ix)
      setFamVisible(famList.slice(ix,ix + 1))
      fetchFam(ix)
    }
    
  }

  const prevFam = () => {
    if (activeFam > 0) {
      let ix = activeFam - 1
      setActiveFam(ix)
      setFamVisible(famList.slice(ix,ix + 1))
      fetchFam(ix)
    }
    
  }

  React.useEffect(()=>{
    setFamVisible(famList.slice(0,1))
    fetchFam(activeFam)
  }, [famList])

  const t = () => {
    setIsPending(true)
    let data = fam.methods.safeTransferFrom(window.ethereum.selectedAddress, tref.current.value, Number(famVisible[0])).encodeABI()
    window.ethereum.request({
      method: "eth_sendTransaction",
      // The following sends an EIP-1559 transaction. Legacy transactions are also supported.
      params: [
        {
          // The user's active address.
          from: window.ethereum.selectedAddress,
          // Required except during contract publications.
          to: tokens["fam"]["address"],
          // Only required to send ether to the recipient from the initiating external account.
          value: 0,
          data: data,
        },
      ],
    })
      .then((res)=> {
        setIsPending(false)
      }).catch((err) => {
        setIsPending(false)
      })
  }

  const tref = React.createRef()

 



  


  return (
    <div className="App">
      
        <div className="header">
          <div className="header__img"><img src={famgif} /></div>
          <div className="header__text">Familiars</div>
        </div>
      
        <div className="core">
          <p>You have {fs(famBal)} Familiars</p>
          <div className="familiars">
            {
              famVisible.map((x)=>
                <div className="familiar">
                  <div className="familiar__img"><img src={famImg} /></div>
                  <div className="familiar__text">Familiar # {Number(x)}</div>
                </div>
              )
            }
            
          </div>
          <button onClick={prevFam}>Previous Fam</button>
          <button onClick={nextFam}>Next Fam</button>

          

          <div className="transfer">
            Transfer ID #{Number(famVisible[0])} to <input ref={tref} /> <button onClick={t}>Transfer</button>
          </div>

          <div className="attrs">
            <ul>
            {
              famAttr.map((x)=><li>
                {x.trait_type} : {x.value}
              </li>)
            }
            </ul>
          </div>

        </div>

        <div className={"pending pending--" + isPending}>Pending Transaction</div>
        <button 
          disabled={isDisabled} 
          onClick={connectMM} 
          className="mmbtn">
          {mmBtnText}
        </button>
    </div>
  );
}

export default App;
